/**
 *  Created by pw on 2019-10-23 15:07.
 */
import key from './key';
export default [
    {
        id: key.ID
    },
    {
        point: '@@components',
        namespace: key.ID,
        onload: function () { return [{ key: 'CheckInView', component: function () { return import('./check-in'); } }]; }
    },
    {
        point: '@@layers',
        prefix: key.ID,
        onload: function () { return import('./layers'); }
    }
];
